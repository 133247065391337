export default {
  menu: [
    {
      label: "Agencias",
      icon: "pi pi-fw pi-user-edit icon-color pt-1",
      to: "/agencias",
    },
    {
      label: "Taquillas",
      icon: "pi pi-fw pi-user-edit icon-color pt-1",
      to: "/taquillas",
    },
  ],
};
